<template>
    <section  v-if="!this.affiliation">
        <div class="columns">
            <div class="input-label title is-6 column">
                <div>Promotional Code</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <div class="column is-two-fifths">
                <input type="text" class="input" v-model="quoteData.promocode">
            </div>

            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

        </div>

        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p>
                    We sometimes offer discount codes, if you have come to us via an advertisement or promotion please enter the code to receive your discount. If you don't have promotion code, just click Get Quote.
                </p>
            </div>
        </transition>
    </section>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "PromoCodeInput",
        data() {
            return {
                showInfo: false 
            }
        },
        props: ['quoteData'],
        computed: {
          ...mapGetters({
            affiliation: 'affiliation'
          })
        },
    }
</script>

<style scoped>
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
</style>