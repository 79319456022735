<template>
    <div>
        <hr>
        <h2 class="title is-5">IMPORTANT INFORMATION & HOW TO DECLARE MEDICAL CONDITIONS</h2>

        <div id="assumptions">
            <p>Your quote today is made using certain assumptions...</p>
            <button class="button is-info info-modal-button" @click.prevent="modalActive = true">To view these please click here</button>
        </div>

        <div id="confirm-correct">
            <p>Are you happy to confirm these to be correct?</p>
            <b-field class="confirm-radio">
                <b-radio-button v-model="conf" :native-value="false" type="is-danger">
                    <b-icon icon="close"/> No
                </b-radio-button>
                <b-radio-button v-model="conf" :native-value="true" type="is-success">
                    <b-icon icon="check"/> Yes
                </b-radio-button>
            </b-field>
        </div>

        <section>
            <b-modal :active.sync="modalActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-centered has-text-weight-bold">IMPORTANT INFORMATION & HOW TO DECLARE MEDICAL CONDITIONS</p>
                    </header>
                    <section class="modal-card-body">
                        <div class="content">
                            <p>
                                Once you have completed your purchase, you will be sent your policy documents. It is
                                important that you take the time to read them so that you know what is and what is not
                                covered.
                            </p>

                            <h2>PRE-EXISTING MEDICAL CONDITIONS</h2>

                            <p>
                                Covered2go may provide cover for pre-existing medical conditions, so long as these have been declared to and accepted by our Medical Screening Service.
                            </p>
                            <p>    
                                You can complete your medical screening online, <a href="https://www.ctg.millstreamonline.com/standalonequote/start" target="_blank">simply click here to get started</a>. The link will open a new webpage and you can begin screening immediately. 
                            </p>
                            <p>
                                You can arrange cover for pre-existing medical conditions before or after you purchase your Covered2go Policy, but please note, two transactions will be required i.e., to purchase the policy for medical cover and your Covered2go Policy.
                            </p>
                            <p>
                                We will be integrating medical screening into our customer journey soon, to further enhance your online experience and we appreciate your patience with us while we get this in place.
                            </p>
                            <p>
                                If you would prefer to speak to the Medical Screening Service, please call +44 (0)330 311 2648.
                            </p>

                            <h2>
                                IMPORTANT INFORMATION:
                            </h2>

                            <p>
                                For full travel insurance cover with medical conditions, you must purchase a policy from
                                <a href="https://www.covered2go.co.uk/" target="_blank" >covered2go.co.uk</a>, as well
                                as declaring your conditions to our Medical Screening Service, the two policies will run
                                concurrently.
                            </p>

                            <p>
                                Make a note of the Covered2go product that most suits your needs and give our Medical
                                Screening Service a call if your pre-existing medical condition is not listed below.
                            </p>

                            <h2>
                                AUTOMATICALLY COVERED PRE-EXISTING MEDICAL CONDITIONS
                            </h2>

                            <p>
                                You do not need to declare and be screened for any of the following automatically covered
                                medical conditions, they will be covered for no additional premium. Provided that you do
                                not also have a pre-existing medical condition which is not listed below.
                            </p>

                            <p class="grey-box">
                                Acne, ADHD, Allergic reaction (Anaphylaxis) provided that you have not needed hospital
                                treatment in the last 2 years, Allergic rhinitis, Alzheimer’s Disease, Arthritis (the
                                affected person must be able to walk independently at home without using mobility aids),
                                Asthma (controlled only using a reliever and a preventer inhaler and with no history of
                                hospital admissions or a past medical history of chest infection / pneumonia in the last
                                6 months), Blindness or partial sightedness, Carpel tunnel syndrome, Cataracts, Chicken
                                pox (if completely resolved), Cholesterol Hyper/ Hypo, Coeliac Disease, Colour Blindness,
                                Common cold or flu, Cuts and abrasions (that are not self-inflicted and require no
                                further treatment), Cystitis (providing there is no ongoing treatment), Deafness/
                                Impaired Hearing, Diabetes (Type 2 controlled by diet only with no history of admissions
                                or related medical conditions), Diarrhoea and vomiting (if completely resolved), Downs
                                Syndrome, Dyspepsia, Eczema, Enlarged prostate (benign only), Essential tremor, Glaucoma,
                                Gout, Haemorrhoids, Hay fever, HRT, Hyperlipidemia, Indigestion, Irritable Bowel Syndrome,
                                Lichen Planus, Ligament or tendon injury (provided that you are not currently being
                                treated), Macular degeneration, Melanosis, Menopause, Migraine (providing there are no
                                ongoing investigations), Nasal polyps, Night Blindness, Parkinson’s Disease, Physically
                                Disabled, PMT, Psoriasis, Raynaud’s Syndrome, Rhinitis, Rosacea, RSI, Sinusitis
                                (providing there is no ongoing treatment), Skin or wound infections (that have completely
                                resolved with no current treatment), Tinnitus, Underactive Thyroid (Hypothyroidism),
                                Urticaria, Varicose veins in the legs, Vertigo.
                            </p>

                            <h2>DO I NEED TO DECLARE MY CONDITIONS?</h2>

                            <p>
                                To ensure you are provided with the appropriate cover, you must advise us to the best of
                                your knowledge if any of the following apply to you:
                            </p>

                            <ol>
                                <li>You have, during the 24 months before this insurance started, suffered from or received treatment, advice or medication for any chronic, ongoing or recurring illness or condition. (A chronic condition is one lasting three months or more).</li>
                                <li>You are receiving, recovering from, or on a waiting list for in-patient treatment in a hospital or nursing home.</li>
                                <li>You are waiting for the results of tests or investigations, or awaiting a referral for an existing medical condition.</li>
                            </ol>

                            <p>
                                Failure to contact us could leave you with no right to make a claim, and may mean that you
                                travel with insufficient cover. If cover can be provided for your condition, you will be
                                given a medical endorsement upon receipt of payment.
                            </p>

                            <p>
                                Terms and Conditions Apply. Please see our
                                <router-link to="policy-wordings-mul">Policy Wording</router-link> for full details.
                            </p>

                            <h2>MEDICAL CONDITIONS OF A THIRD PARTY</h2>

                            <p>
                                In respect of a travelling companion, an immediate relative, close business associate or
                                person who you plan to stay with during your trip, who is not a named on your schedule,
                                but on whose health the trip depends, this policy will not cover any claims under
                                Section 1: Cancellation or Section 2: Curtailment, that result directly or indirectly
                                from any pre-existing medical condition you knew about at the time of taking out this
                                insurance or when booking a trip, and that affects:
                            </p>

                            <ul>
                                <li>a travelling companion who is not insured under this policy;</li>
                                <li>an immediate relative who is not travelling and is not insured under this policy;</li>
                                <li>a close business associate; or</li>
                                <li>a person you plan to stay with on your trip.</li>
                            </ul>

                            <p>
                                Subject to the terms and conditions, such claims may only be covered if their medical
                                practitioner is prepared to state that at the date you bought this policy or of booking
                                a trip, they would have seen no substantial likelihood of their patient’s condition
                                deteriorating to such a degree that you would need to cancel your trip. If the medical
                                practitioner will not confirm this, your claim is not covered. In the event of a claim,
                                the medical practitioner must complete the medical certificate on the claim form.
                            </p>

                            <h2>CHANGE IN HEALTH</h2>

                            <p>
                                Failure to contact us could leave you with no right to make a claim, and may mean that
                                you travel with insufficient cover. If there is any change to a pre-existing condition
                                or if any of the reasons stated above occur between the date the certificate wording is
                                issued and / or before the first day of your trip, you must notify the Medical Screening
                                Service on +44 (0)330 311 2648.
                            </p>

                            <p>
                                We will then decide if we can provide you with cover on existing terms. We may ask you
                                to pay an additional premium or add special conditions to the policy. If we cannot
                                provide cover, or if you do not want to pay the additional premium, you can make a
                                cancellation claim if you have booked and paid for a trip that you have not yet made.
                                Alternatively, you can cancel your policy and we will send you a pro rata refund.
                            </p>

                            <p>
                                We reserve the right not to extend this insurance if deemed necessary by us, where the
                                booked trip could be detrimental to your well-being. Failure to contact us could leave
                                you with no right to make a claim and may mean that you travel with insufficient cover.
                                If cover can be provided for your condition. You will be given a medical endorsement upon
                                receipt of payment.
                            </p>

                            <h2>PREGNANCY</h2>

                            <p>
                                As is consistent with the treatment of all medical conditions under the policy, the policy
                                does not intend to cover the normal costs or losses otherwise associated with pregnancy
                                (including multiple pregnancy) or childbirth. This includes, but is not limited to,
                                delivery by caesarean section or any other medically or surgically assisted delivery
                                which does not cause medical complications. The policy does, however, cover you should
                                complications arise with your pregnancy due to accidental bodily injury or unexpected
                                illness which occurs while on your trip excluding costs incurred during the period between
                                12 weeks before and 12 weeks after the estimated date of delivery.
                            </p>

                            <h2>This is not a private medical health insurance policy </h2>

                            <p>
                                We will pay for private treatment only if there is no appropriate reciprocal health
                                agreement in existence and no public service available and we reserve the right to
                                organise a transfer from a private medical facility to a public medical facility where
                                appropriate. In the event of medical treatment becoming necessary for which reimbursement
                                will be sought, we or our representatives will require unrestricted access to all of
                                your medical records and information.
                                <br><br>
                                <strong>Limits expressed within the Policy are all 'Per Person'</strong>.
                            </p>

                            <h2>Cancellation Cover</h2>

                            <p>
                                Please ensure that the cancellation cover on the policy is adequate to cover the
                                cancellation value of your trip, as insufficient cover may impact on <strong>all</strong>
                                sections of your policy and result in a lower level of cover being provided.
                            </p>

                            <h2>Curtailment</h2>

                            <p>
                                You should also be aware that if you have to cut your trip short, your curtailment claim
                                will only be calculated from the date that you actually return home, there is no cover
                                for loss of enjoyment.
                            </p>

                            <h2>Cruise</h2>

                            <p>
                                If you are insuring a cruise holiday, please ensure that you have selected the specific
                                cruise option. Also ensure that the cancellation cover on the policy is adequate to cover
                                the cancellation value of your trip, as insufficient cover may impact on
                                <strong>all</strong> sections of your policy and result in a lower level of cover being
                                provided.
                            </p>

                            <h2>Winter Sports</h2>

                            <p>
                                If you are planning on insuring a winter sports holiday, please ensure that you have
                                selected the specific winter sports extension and ensure that your activity is covered.
                            </p>

                            <h2>Significant Exclusions</h2>

                            <p>
                                Please be aware that Covered2go does not provide cover for any claim for:
                            </p>

                            <ul>
                                <li>Anyone travelling against the advice of the Foreign, Commonwealth and Development Office</li>
                                <li>Any claim where at the time of purchase you had reasonable knowledge of circumstances that could lead to a claim against the policy.</li>
                                <li>Private treatment, unless authorised by Millstream in advance and only where there are no clinically capable state facilities available.</li>
                            </ul>

                            <h2>Eligibility Statement</h2>

                            <p>
                                Your schedule shows the persons who are insured under the policy and any special terms
                                and conditions that may apply. Cover is only available to persons resident in the 
                                United Kingdom or the Channel Islands and is only valid for round trips starting and 
                                returning to the United Kingdom or the Channel Islands. You must have a permanent 
                                residential address in the United Kingdom or the Channel Islands and 
                                unrestricted right of entry to the United Kingdom or the Channel Islands.
                            </p>
                        </div>
                    </section>
                    <footer class="modal-card-foot level">
                        <button class="button level-item is-info" type="button" @click.prevent="modalActive = false"><strong>RETURN TO QUOTE</strong></button>
                    </footer>
                </div>
            </b-modal>
        </section>
    </div>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "ImportantInformation",
        data() {
            return {
                modalActive: false
            }
        },
        props: {
            value: {
                type: Boolean
            }
        },
        computed: {
            conf: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                }
            }
        },
        mixins: [ contentAPI, page ],
    }
</script>

<style lang="scss" scoped>
    h2 {
        color: $c2g_blue;
        margin: 5px 0 10px 0 !important;
    }
    #confirm-correct {
        margin: 20px 0 20px 0;
    }

    #assumptions {
        margin: 20px 0 20px 0;
    }
    .red {
        background-color: red;
    }

    .grey-box {
        text-align: center;
        padding: 4px;
        background-color: lightgrey;
        border-radius: 5px;
    }

    .content /deep/ {
        h2 {
            color: $c2g_blue;
            font-weight: bold;
            font-size: 20px;
            margin: 15px 0 5px 0;
        }

        li {
            margin-left: 25px;
            margin-bottom: 10px;
            position: relative;
            list-style-type: none;
        }

        li:before {
            content: "";
            position: absolute;
            top: 3px;
            left: -25px;
            width: 20px;
            height: 20px;
            background-image: url('../../../assets/images/icons/bullet-tick-sm.png');
        }
    }
    .confirm-radio {
        margin: 10px 0 20px 0;
    }
    .info-modal-button {
        margin-top: 10px;
    }
</style>